'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaVolumeControlPhone = function FaVolumeControlPhone(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm18.3 37.7q0-0.2-0.3-1.3t-0.7-2.4-0.5-1.5q0-0.1-0.1-0.6t-0.2-0.8-0.3-0.5q-0.3-0.3-1.1-0.3-0.5 0-1.6 0.1t-1.5 0.1q-0.8 0-1.1-0.2-0.1-0.1-0.2-0.4t-0.2-0.4-0.2-0.5-0.1-0.5q-0.8-2.8-0.8-5.6t0.8-5.7q0.1-0.1 0.1-0.4t0.2-0.6 0.2-0.4 0.2-0.4q0.3-0.2 1.1-0.2 0.5 0 1.5 0.1t1.6 0.1q0.8 0 1.1-0.3 0.2-0.2 0.3-0.5t0.2-0.8 0.1-0.6q0.1-0.2 0.5-1.5t0.7-2.4 0.3-1.3q0-0.5-1-1.2t-1.7-0.9q-0.4-0.2-1-0.2-0.7 0-2.2 0.4-1.2 0.4-2.1 0.9t-1.6 1.5-1 1.6-1.1 2.1q-0.1 0.2-0.2 0.4-1.1 2.4-1.5 4.8t-0.4 5.5 0.4 5.4 1.5 4.8q1.3 2.7 1.9 3.6 1.4 2 4 2.8l0.1 0.1q1.5 0.4 2.2 0.4 0.6 0 1-0.2 0.7-0.3 1.7-0.9t1-1.2z m3.5-20.4q-0.6 0-1-0.4t-0.4-1 0.4-1q0.8-0.9 0.8-2 0-1.2-0.8-2.1-0.4-0.4-0.4-1t0.4-1 1-0.4 1 0.4q1.7 1.7 1.7 4.1t-1.7 4q-0.4 0.4-1 0.4z m4.1 4.1q-0.6 0-1-0.5-0.5-0.4-0.5-1t0.5-1q2.5-2.5 2.5-6t-2.5-6.1q-0.5-0.4-0.5-1t0.5-1 1-0.4 1 0.4q3.3 3.3 3.3 8.1t-3.3 8q-0.4 0.5-1 0.5z m4 4q-0.6 0-1-0.4-0.4-0.4-0.4-1t0.4-1q2-2.1 3.1-4.7t1.1-5.4-1.1-5.5-3.1-4.7q-0.4-0.4-0.4-1t0.4-1 1-0.4 1 0.4q2.4 2.5 3.7 5.6t1.3 6.6-1.3 6.5-3.7 5.6q-0.4 0.4-1 0.4z' })
        )
    );
};

exports.default = FaVolumeControlPhone;
module.exports = exports['default'];