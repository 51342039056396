'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaReddit = function FaReddit(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm24.4 26.1q0.4 0.3 0 0.6-1.3 1.4-4.4 1.4t-4.4-1.4q-0.4-0.3 0-0.6 0.1-0.2 0.3-0.2t0.3 0.2q1.1 1 3.8 1 2.7 0 3.8-1.1 0.1-0.1 0.3-0.1t0.3 0.1z m-6.8-4.1q0 0.8-0.6 1.4t-1.4 0.6-1.4-0.6-0.6-1.4q0-0.8 0.6-1.4t1.4-0.6 1.4 0.6 0.6 1.4z m8.8 0q0 0.8-0.6 1.4t-1.4 0.6-1.4-0.6-0.6-1.4 0.6-1.4 1.4-0.6 1.4 0.6 0.6 1.4z m5.6-2.7q0-1.1-0.8-1.8t-1.9-0.8-1.9 0.8q-2.9-2-6.9-2.2l1.4-6.3 4.4 1q0 0.8 0.6 1.4t1.4 0.6 1.4-0.6 0.6-1.4-0.6-1.4-1.4-0.6q-1.2 0-1.8 1.1l-4.9-1.1q-0.4-0.1-0.6 0.4l-1.5 6.9q-4 0.2-6.9 2.2-0.8-0.8-1.9-0.8-1.1 0-1.9 0.8t-0.8 1.8q0 0.8 0.4 1.5t1.1 0.9q-0.1 0.6-0.1 1.3 0 3.2 3.1 5.4t7.5 2.3q4.4 0 7.6-2.3t3.1-5.4q0-0.7-0.2-1.3 0.7-0.3 1.1-1t0.4-1.4z m8 0.7q0 4.1-1.6 7.8t-4.2 6.4-6.4 4.2-7.8 1.6-7.8-1.6-6.4-4.2-4.2-6.4-1.6-7.8 1.6-7.8 4.2-6.4 6.4-4.2 7.8-1.6 7.8 1.6 6.4 4.2 4.2 6.4 1.6 7.8z' })
        )
    );
};

exports.default = FaReddit;
module.exports = exports['default'];