'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaDollar = function FaDollar(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm30.3 26.4q0 3.5-2.2 5.9t-5.8 3.1v3.9q0 0.3-0.2 0.5t-0.5 0.2h-3q-0.3 0-0.5-0.2t-0.2-0.5v-3.9q-1.5-0.2-2.8-0.7t-2.3-1-1.7-1.1-1-0.8-0.4-0.4q-0.4-0.5 0-0.9l2.3-3q0.1-0.3 0.5-0.3 0.3-0.1 0.5 0.2l0.1 0q2.5 2.2 5.4 2.8 0.8 0.2 1.6 0.2 1.8 0 3.2-1t1.4-2.7q0-0.6-0.4-1.2t-0.7-0.9-1.3-0.8-1.5-0.8-1.8-0.7q-0.8-0.3-1.3-0.5t-1.4-0.6-1.4-0.7-1.3-0.8-1.2-1-0.9-1.1-0.8-1.2-0.5-1.5-0.2-1.8q0-3.1 2.2-5.4t5.7-3v-4q0-0.3 0.2-0.5t0.5-0.2h3q0.3 0 0.5 0.2t0.2 0.5v3.9q1.3 0.2 2.5 0.6t1.9 0.7 1.5 0.8 0.8 0.7 0.4 0.3q0.3 0.4 0.1 0.9l-1.8 3.2q-0.2 0.3-0.5 0.4-0.4 0-0.6-0.2-0.1-0.1-0.4-0.3t-0.8-0.5-1.3-0.8-1.7-0.5-1.9-0.3q-2.1 0-3.5 1t-1.3 2.4q0 0.6 0.2 1.1t0.6 0.9 0.9 0.8 1.3 0.7 1.3 0.6 1.6 0.6q1.2 0.4 1.8 0.7t1.7 0.8 1.7 0.9 1.4 1.1 1.1 1.5 0.7 1.7 0.3 2.1z' })
        )
    );
};

exports.default = FaDollar;
module.exports = exports['default'];