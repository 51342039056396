'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaYahoo = function FaYahoo(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm22.2 21.4l0.3 15.7q-1.4-0.2-2.4-0.2-0.9 0-2.3 0.2l0.3-15.7q-0.9-1.6-3.8-6.6t-4.8-8.4-4.1-6.4q1.3 0.3 2.4 0.3 1 0 2.5-0.3 1.4 2.5 3 5.1t3.7 6.2 3.1 5.1q0.8-1.4 2.5-4t2.6-4.2 2.3-4 2.4-4.2q1.2 0.3 2.4 0.3 1.3 0 2.6-0.3-0.7 0.9-1.4 2t-1.1 1.7-1.3 2.2-1 1.8q-3.3 5.6-7.9 13.7z' })
        )
    );
};

exports.default = FaYahoo;
module.exports = exports['default'];