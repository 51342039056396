'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaMoney = function FaMoney(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm15.9 25.3h8v-2h-2.6v-9.3h-2.4l-3.1 2.9 1.6 1.6q0.9-0.7 1.2-1.2h0v6h-2.7v2z m10.7-5.3q0 1.5-0.5 2.9t-1.2 2.8-2.1 2.1-2.9 0.8-2.8-0.8-2.1-2.1-1.3-2.8-0.4-2.9 0.4-2.9 1.3-2.8 2.1-2.1 2.8-0.8 2.9 0.8 2.1 2.1 1.2 2.8 0.5 2.9z m10.6 5.3v-10.6q-2.2 0-3.7-1.6t-1.6-3.7h-23.9q0 2.2-1.6 3.7t-3.7 1.6v10.6q2.2 0 3.7 1.6t1.6 3.7h23.9q0-2.2 1.6-3.7t3.7-1.6z m2.7-17.3v24q0 0.5-0.4 0.9t-1 0.4h-37.2q-0.5 0-0.9-0.4t-0.4-0.9v-24q0-0.5 0.4-0.9t0.9-0.4h37.2q0.6 0 1 0.4t0.4 0.9z' })
        )
    );
};

exports.default = FaMoney;
module.exports = exports['default'];