'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaAndroid = function FaAndroid(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm15.5 10.8q0.4 0 0.6-0.3t0.3-0.6-0.3-0.6-0.6-0.3-0.6 0.3-0.2 0.6 0.2 0.6 0.6 0.3z m9.4 0q0.4 0 0.6-0.3t0.3-0.6-0.3-0.6-0.6-0.3-0.6 0.3-0.2 0.6 0.2 0.6 0.6 0.3z m-18.1 4.1q0.9 0 1.6 0.7t0.7 1.6v9.6q0 0.9-0.7 1.6t-1.6 0.7-1.6-0.7-0.7-1.6v-9.6q0-1 0.7-1.6t1.6-0.7z m23.7 0.4v14.9q0 1-0.8 1.7t-1.7 0.7h-1.6v5.1q0 1-0.7 1.6t-1.6 0.7-1.7-0.7-0.6-1.6v-5.1h-3.1v5.1q0 1-0.7 1.6t-1.6 0.7q-1 0-1.6-0.7t-0.7-1.6l0-5.1h-1.7q-1 0-1.7-0.7t-0.7-1.7v-14.9h20.5z m-5.2-9q2.4 1.2 3.8 3.4t1.4 4.8h-20.6q0-2.6 1.4-4.8t3.8-3.4l-1.5-3q-0.2-0.2 0.1-0.4 0.3-0.1 0.4 0.1l1.6 3q2.1-1 4.5-1t4.5 1l1.6-3q0.2-0.2 0.5-0.1 0.2 0.2 0.1 0.4z m10.6 10.9v9.6q0 0.9-0.6 1.6t-1.7 0.7q-0.9 0-1.6-0.7t-0.6-1.6v-9.6q0-1 0.6-1.7t1.6-0.6q1 0 1.7 0.6t0.6 1.7z' })
        )
    );
};

exports.default = FaAndroid;
module.exports = exports['default'];