'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaStickyNoteO = function FaStickyNoteO(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm34.3 28.6h-5.6v5.5q0.7-0.2 0.9-0.5l4.2-4.1q0.2-0.3 0.4-0.9z m-6.3-2.9h6.4v-20h-28.5v28.6h20v-6.4q0-0.9 0.6-1.6t1.5-0.6z m9.3-20.7v22.9q0 0.9-0.5 1.9t-1 1.7l-4.1 4.1q-0.7 0.7-1.7 1.1t-2 0.4h-22.9q-0.8 0-1.5-0.6t-0.6-1.5v-30q0-0.9 0.6-1.5t1.5-0.6h30q0.9 0 1.6 0.6t0.6 1.5z' })
        )
    );
};

exports.default = FaStickyNoteO;
module.exports = exports['default'];