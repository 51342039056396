'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaCcStripe = function FaCcStripe(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm28 20.1q0 1.2-0.4 1.9-0.3 0.6-0.9 0.6-0.4 0-0.7-0.2v-3.9q0.5-0.5 1-0.5 1 0 1 2.1z m7.6-0.6h-1.9q0.1-1.7 1-1.7 0.9 0 0.9 1.7z m-27.3 2.4q0-1.1-0.5-1.6t-1.8-1.1q-0.6-0.2-0.9-0.4t-0.3-0.4q0-0.5 0.7-0.5 1 0 2.1 0.6l0.3-1.9q-1.1-0.6-2.6-0.6-1.3 0-2.1 0.7-0.9 0.6-0.9 1.9 0 1 0.6 1.5t1.8 1q0.6 0.3 0.9 0.5t0.3 0.5q0 0.5-0.9 0.5-0.5 0-1.2-0.2t-1.2-0.5l-0.4 1.9q1.3 0.8 3 0.8 1.4 0 2.2-0.7 0.9-0.7 0.9-2z m5.2-3.8l0.3-2h-1.7v-2.3l-2.2 0.4-0.3 1.9-0.8 0.2-0.3 1.8h1.1v3.8q0 1.5 0.7 2.1 0.7 0.6 2 0.6 0.5 0 1.4-0.2v-2.1q-0.6 0.1-0.8 0.1-0.8 0-0.8-0.9v-3.4h1.4z m5.5 0.4v-2.4q-0.2-0.1-0.5-0.1-0.5 0-0.9 0.3t-0.6 0.8l-0.2-1h-2.3v8.3h2.6v-5.4q0.5-0.5 1.5-0.5 0.3 0 0.4 0z m0.7 5.9h2.6v-8.3h-2.6v8.3z m10.9-4.4q0-2.1-0.8-3.1-0.7-0.9-2-0.9-1.1 0-2 1l-0.2-0.9h-2.3v11.3l2.7-0.4v-2.6q0.6 0.2 1.2 0.2 1.4 0 2.3-1 1.1-1.2 1.1-3.6z m-8.2-6.1q0-0.5-0.4-0.9t-1-0.4-1 0.4-0.4 0.9 0.4 1 1 0.4 1-0.4 0.4-1z m15.7 6.3q0-2-0.8-3.1-0.9-1.1-2.6-1.1-1.6 0-2.6 1.1t-1 3.2q0 2.2 1.1 3.3 1 1 2.8 1 1.8 0 2.8-0.7l-0.2-1.9q-1 0.6-2.3 0.6-0.7 0-1.1-0.3-0.4-0.4-0.5-1.2h4.4q0-0.2 0-0.9z m2.2-11.4v22.4q0 0.9-0.6 1.6t-1.6 0.6h-35.9q-0.9 0-1.5-0.6t-0.7-1.6v-22.4q0-0.9 0.7-1.6t1.5-0.6h35.9q0.9 0 1.6 0.6t0.6 1.6z' })
        )
    );
};

exports.default = FaCcStripe;
module.exports = exports['default'];