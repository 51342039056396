'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaTextWidth = function FaTextWidth(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm4.8 2.9l1.2 0.6q0.3 0.1 4.7 0.1 1 0 3-0.1t2.9 0q1.6 0 5.5 0t6.8 0 5.5 0.1q0.8 0 1.3-0.7l0.9 0q0.1 0 0.3 0t0.3 0q0.1 2.5 0.1 7.5 0 1.8-0.1 2.4-0.9 0.3-1.5 0.4-0.6-1-1.2-2.8-0.1-0.2-0.3-1.1t-0.3-1.6-0.2-0.9q-0.2-0.2-0.6-0.4-0.1 0-1.5 0-0.6 0-2 0t-2.3-0.1-2.1 0.1-2.2 0.1q-0.2 1.8-0.2 3.1l0.1 3.4v-1.2q0 1.2 0 3.4t0 4.1 0 3.4q0 0.3 0 1.6t0 2 0.3 1.6q0.8 0.4 2.7 0.9t2.7 0.8q0.1 0.9 0.1 1.2 0 0.3-0.1 0.6l-0.7 0q-1.7 0.1-4.9-0.2t-4.6-0.2q-1.1 0-3.4 0.2t-3.4 0.2q0-1.1 0-1.1v-0.2q0.3-0.6 1.3-1t2.2-0.6 1.8-0.7q0.1-0.3 0.2-1.6t0.2-3.3 0-3.4 0-3.4 0-2q0-0.2-0.1-0.5t0-0.5q0-0.2 0-1t0-1.6 0-1.7-0.1-1.5-0.1-0.7q-0.3-0.3-3.6-0.3-0.9 0-3.7 0.3t-3 0.5q-0.5 0.3-0.8 1.6t-0.7 2.5-1 1.2q-0.9-0.6-1.2-1v-8.5z m27.4 28.6q0.3 0 1 0.4t1.3 1 1.3 1.1 0.8 0.6q0.6 0.5 0.6 1.1t-0.6 1.1q-0.1 0.1-0.8 0.7t-1.3 1.1-1.3 0.9-1 0.4q-0.3 0-0.4-0.2t-0.2-0.6-0.1-0.8 0-0.7 0.1-0.5h-22.9q0 0.1 0 0.5t0.1 0.7-0.1 0.8-0.2 0.6-0.5 0.2q-0.2 0-0.9-0.4t-1.3-0.9-1.3-1.1-0.8-0.7q-0.6-0.5-0.6-1.1t0.6-1.1q0.1-0.1 0.8-0.7t1.3-1 1.3-1 0.9-0.4q0.3 0 0.5 0.2t0.2 0.7 0.1 0.7-0.1 0.7 0 0.5h22.9q0-0.1-0.1-0.5t0-0.7 0.1-0.7 0.2-0.7 0.4-0.2z' })
        )
    );
};

exports.default = FaTextWidth;
module.exports = exports['default'];