'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaParagraph = function FaParagraph(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm34 4.2v1.6q0 0.7-0.4 1.4t-0.9 0.7q-1.2 0-1.2 0-0.6 0.2-0.8 0.7 0 0.3 0 1.5v25.7q0 0.5-0.4 0.9t-1 0.4h-2.4q-0.6 0-1-0.4t-0.4-0.9v-27.2h-3.1v27.2q0 0.5-0.4 0.9t-1 0.4h-2.4q-0.6 0-1-0.4t-0.4-0.9v-11.1q-3.3-0.3-5.5-1.3-2.8-1.3-4.2-4-1.5-2.6-1.5-5.8 0-3.7 2-6.4 2-2.6 4.7-3.5 2.4-0.8 9.3-0.8h10.7q0.5 0 0.9 0.4t0.4 0.9z' })
        )
    );
};

exports.default = FaParagraph;
module.exports = exports['default'];