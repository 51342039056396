'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaTint = function FaTint(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm19.9 25.7q0-0.8-0.4-1.5 0 0-0.4-0.5t-0.5-0.9-0.6-1-0.5-1.1q0-0.3-0.4-0.3t-0.5 0.3q-0.2 0.5-0.5 1.1t-0.5 1-0.6 0.9-0.3 0.5q-0.5 0.7-0.5 1.5 0 1.2 0.9 2t2 0.9 2-0.9 0.8-2z m11.5-2.8q0 4.7-3.4 8t-8.1 3.4-8.1-3.4-3.3-8q0-3.3 1.8-6.2 0.1-0.2 1.4-2t2.3-3.4 2.2-3.9 1.8-4.5q0.2-0.7 0.8-1.1t1.1-0.4 1.2 0.4 0.7 1.1q0.7 2 1.9 4.5t2.2 3.9 2.3 3.4 1.3 2q1.9 2.9 1.9 6.2z' })
        )
    );
};

exports.default = FaTint;
module.exports = exports['default'];