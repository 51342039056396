'use strict'

var unified = require('unified')
var parse = require('remark-parse')
var stringify = require('remark-stringify')

module.exports = unified()
  .use(parse)
  .use(stringify)
  .freeze()
