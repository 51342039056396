'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaDrupal = function FaDrupal(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm29.1 35.4q-0.2-0.4-0.6-0.1-0.7 0.5-1.9 0.9t-3 0.3q-2.8 0-4.3-1.1-0.1 0-0.2 0-0.3 0-0.6 0.2-0.2 0.2-0.2 0.4t0.2 0.4q0.7 0.7 1.9 1.1t2.3 0.2 2.2-0.1q0.9-0.1 1.9-0.4t1.5-0.7 0.6-0.5q0.3-0.2 0.1-0.6z m-0.9-2.6q-0.4-1-0.9-1.3-0.5-0.4-1.7-0.4-1 0-1.6 0.3-0.6 0.2-1.7 1.2-0.6 0.5-0.3 1 0.2 0.2 0.4 0.1t0.7-0.5q0.1-0.1 0.2-0.2t0.3-0.2 0.2-0.2 0.3-0.1 0.2-0.1 0.4-0.1 0.3-0.1 0.5 0q0.6 0 1 0.2t0.5 0.3 0.3 0.5q0.2 0.3 0.3 0.4t0.3 0q0.5-0.3 0.3-0.8z m7.9-6.2q0-0.5-0.1-1t-0.4-1-0.7-0.8-1.2-0.4q-0.7 0-2.2 1t-2.9 1.8-2.2 1q-0.6 0-1.4-0.5t-1.7-1.1-1.9-1.2-2.2-1.1-2.5-0.5q-2.6 0.1-4.4 1.8t-1.9 4q0 2.5 1.7 3.6 0.6 0.5 1.4 0.7t2.3 0.2q1.3 0 2.9-0.8t3-1.6 2.7-1.5 2.1-0.7q0.6 0 1.4 0.7t1.6 1.5 1.6 1.5 1.2 0.7q0.8 0.1 1.3-0.3t1.2-1.4q0.7-0.9 1-2.3t0.3-2.3z m1.2-3.6q0 3.7-1.4 6.8t-3.7 5.3-5.4 3.3-6.5 1.2-6.5-1.3-5.6-3.5-3.8-5.4-1.4-6.7q0-2 0.4-3.9t1.1-3.2 1.6-2.7 1.8-2.1 1.7-1.5 1.4-1.1 1-0.5q0.3-0.2 1.1-0.6t1.2-0.6 1.1-0.7 1.4-1q0.8-0.6 1.3-1.6t0.6-2.8q2.9 3.5 4.2 4.3 1 0.7 2.9 1.5t2.9 1.5q0.4 0.3 0.8 0.6t1.4 1 1.7 1.6 1.7 2.1 1.5 2.7 1.1 3.3 0.4 4z' })
        )
    );
};

exports.default = FaDrupal;
module.exports = exports['default'];