'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaFileMovieO = function FaFileMovieO(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm35.8 8.5q0.6 0.6 1 1.7t0.5 1.9v25.8q0 0.8-0.6 1.5t-1.6 0.6h-30q-0.9 0-1.5-0.6t-0.6-1.5v-35.8q0-0.8 0.6-1.5t1.5-0.6h20q0.9 0 2 0.4t1.7 1.1z m-9.9-5.5v8.4h8.4q-0.3-0.6-0.5-0.9l-7-7q-0.3-0.2-0.9-0.5z m8.5 34.1v-22.8h-9.3q-0.9 0-1.5-0.6t-0.6-1.6v-9.2h-17.1v34.2h28.5z m-14.3-20q1.2 0 2.1 0.9t0.8 2v8.6q0 1.1-0.8 2t-2.1 0.8h-8.5q-1.2 0-2-0.8t-0.9-2v-8.6q0-1.2 0.9-2t2-0.9h8.5z m11 0.1q0.5 0.2 0.5 0.7v12.8q0 0.5-0.5 0.7-0.2 0-0.2 0-0.4 0-0.6-0.2l-5.9-5.9v-2l5.9-6q0.2-0.2 0.6-0.2 0 0 0.2 0.1z' })
        )
    );
};

exports.default = FaFileMovieO;
module.exports = exports['default'];