'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaTrademark = function FaTrademark(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm17.4 12.9v2.3q0 0.3-0.2 0.5t-0.5 0.2h-6v16.4q0 0.3-0.2 0.5t-0.4 0.2h-2.8q-0.2 0-0.4-0.2t-0.2-0.5v-16.4h-6.1q-0.2 0-0.4-0.2t-0.2-0.5v-2.3q0-0.3 0.2-0.5t0.5-0.2h16q0.3 0 0.5 0.2t0.2 0.5z m21.1-0.1l1.5 19.5q0 0.3-0.1 0.5-0.2 0.2-0.5 0.2h-2.7q-0.3 0-0.5-0.2t-0.2-0.4l-0.9-11.9-3.8 8.6q-0.2 0.4-0.6 0.4h-2.5q-0.4 0-0.5-0.4l-3.9-8.7-0.9 12q0 0.2-0.2 0.4t-0.4 0.2h-2.7q-0.3 0-0.5-0.2-0.2-0.2-0.2-0.5l1.6-19.5q0-0.2 0.2-0.4t0.4-0.2h2.9q0.4 0 0.6 0.4l4.5 10.5q0.2 0.5 0.4 1.1 0-0.2 0.2-0.5t0.2-0.6l4.5-10.5q0.1-0.4 0.5-0.4h2.9q0.3 0 0.4 0.2t0.3 0.4z' })
        )
    );
};

exports.default = FaTrademark;
module.exports = exports['default'];