'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaGoogleWallet = function FaGoogleWallet(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm9.6 15.1q0.7 0 1.1 0.6 5.8 7.9 7.9 16.8h-9.7q-2.8-9.6-8-16.3-0.2-0.3-0.1-0.7t0.7-0.4h8.1z m12.2 7.8q-1.1 4.3-2.7 8.6-1.8-6.8-5.6-13 0.9-4.8 0.9-9.8 4.6 7.4 7.4 14.2z m2.1-15.4q5.2 7 8.4 15.2t4 16.8h-9.8q-0.9-14.5-12-32h9.4z m15.1 12.5q0 9.2-2.2 17.7-1.4-12.2-7.8-23.6-0.5-6.6-2.3-12.7-0.1-0.4 0.1-0.6t0.6-0.3h7.8q0.5 0 0.8 0.3t0.5 0.7q2.5 8.9 2.5 18.5z' })
        )
    );
};

exports.default = FaGoogleWallet;
module.exports = exports['default'];