'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaFolderOpen = function FaFolderOpen(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm39.9 21.2q0 0.6-0.6 1.4l-7.2 8.4q-0.9 1.1-2.5 1.9t-3.1 0.7h-23.1q-0.7 0-1.3-0.3t-0.5-0.9q0-0.6 0.6-1.4l7.2-8.4q0.9-1.1 2.5-1.8t3.1-0.8h23.1q0.7 0 1.3 0.3t0.5 0.9z m-7.2-7.3v3.4h-17.7q-2 0-4.2 1t-3.5 2.5l-7.3 8.6q0-0.1 0-0.3t0-0.3v-20.4q0-1.9 1.4-3.3t3.4-1.4h6.8q1.9 0 3.3 1.4t1.4 3.3v0.7h11.6q1.9 0 3.4 1.4t1.4 3.4z' })
        )
    );
};

exports.default = FaFolderOpen;
module.exports = exports['default'];