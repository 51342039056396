'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaArrowsAlt = function FaArrowsAlt(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm31.6 12.1l-7.9 7.9 7.9 7.9 3.3-3.2q0.6-0.7 1.5-0.3 0.9 0.4 0.9 1.3v10q0 0.6-0.4 1t-1 0.4h-10q-1 0-1.4-0.9-0.3-0.8 0.4-1.5l3.2-3.2-8-7.9-7.9 7.9 3.2 3.2q0.7 0.7 0.3 1.5-0.3 0.9-1.3 0.9h-10q-0.5 0-1-0.4t-0.4-1v-10q0-0.9 0.9-1.3 0.9-0.4 1.5 0.3l3.2 3.2 8-7.9-7.9-7.9-3.3 3.2q-0.4 0.4-1 0.4-0.2 0-0.5-0.1-0.9-0.4-0.9-1.3v-10q0-0.6 0.4-1t1-0.4h10q1 0 1.3 0.9 0.4 0.8-0.3 1.5l-3.2 3.2 7.9 7.9 8-7.9-3.2-3.2q-0.7-0.7-0.4-1.5 0.4-0.9 1.4-0.9h10q0.5 0 1 0.4t0.4 1v10q0 0.9-0.9 1.3-0.3 0.1-0.5 0.1-0.6 0-1-0.4z' })
        )
    );
};

exports.default = FaArrowsAlt;
module.exports = exports['default'];