'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaMedkit = function FaMedkit(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm28.6 25v-4.3q0-0.3-0.2-0.5t-0.5-0.2h-5v-5q0-0.3-0.2-0.5t-0.6-0.2h-4.2q-0.4 0-0.6 0.2t-0.2 0.5v5h-5q-0.3 0-0.5 0.2t-0.2 0.5v4.3q0 0.3 0.2 0.5t0.5 0.2h5v5q0 0.3 0.2 0.5t0.6 0.2h4.2q0.4 0 0.6-0.2t0.2-0.5v-5h5q0.3 0 0.5-0.2t0.2-0.5z m-14.3-16.4h11.4v-2.9h-11.4v2.9z m-8.6 0v28.5h-0.7q-2.1 0-3.5-1.4t-1.5-3.6v-18.5q0-2.1 1.5-3.6t3.5-1.4h0.7z m26.4 0v28.5h-24.2v-28.5h3.5v-3.6q0-0.9 0.7-1.5t1.5-0.6h12.8q0.9 0 1.5 0.6t0.7 1.5v3.6h3.5z m7.9 5v18.5q0 2.1-1.5 3.6t-3.5 1.4h-0.7v-28.5h0.7q2.1 0 3.5 1.4t1.5 3.6z' })
        )
    );
};

exports.default = FaMedkit;
module.exports = exports['default'];