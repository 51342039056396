'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaAmazon = function FaAmazon(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm34.6 32.9q0.4-0.1 0.6 0t0.2 0.4-0.3 0.7q-0.3 0.4-1 1t-2.1 1.5-3.1 1.7-4.2 1.3-5.2 0.5q-2.6 0-5.3-0.7t-4.6-1.7-3.9-2.3-3-2.4-1.8-1.9q-0.2-0.2-0.3-0.4t0.1-0.3 0.2-0.1 0.2-0.1 0.3 0.1q4.3 2.7 6.7 3.8 8.6 3.9 17.8 2 4.2-0.9 8.7-3.1z m4.6-2.5q0.3 0.3 0.1 1.5t-0.6 2.3q-0.8 1.9-1.9 2.8-0.4 0.3-0.6 0.2t0-0.5q0.5-1.1 1-2.8t0.1-2.2q-0.1-0.1-0.3-0.2t-0.6-0.2-0.7 0-0.8 0-0.7 0-0.7 0.1-0.5 0q-0.1 0.1-0.3 0.1t-0.2 0-0.2 0-0.1 0h-0.3l0 0-0.1 0 0-0.1q-0.1-0.3 1-0.9t2.3-0.6q1.1-0.2 2.4-0.1t1.7 0.6z m-8.8-9.9q0 0.7 0.3 1.4t0.8 1.3 0.8 1 0.7 0.8l0.3 0.2-5 5q-0.9-0.8-1.8-1.7t-1.3-1.3l-0.4-0.4q-0.3-0.3-0.6-0.8-0.8 1.3-2.2 2.3t-2.8 1.4-3.1 0.5-3.1-0.4-2.6-1.5-1.9-2.5-0.7-3.6q0-1.9 0.7-3.5t1.6-2.6 2.3-1.8 2.8-1.3 2.9-0.8 2.6-0.4 2.3-0.1v-2.9q0-1.4-0.5-2.1-0.8-1.2-2.7-1.2-0.1 0-0.4 0t-0.9 0.3-1.2 0.6-1.3 1.4-1 2.1l-6.6-0.6q0-1.3 0.5-2.6t1.5-2.6 2.4-2.1 3.4-1.5 4.2-0.5q2.3 0 4.1 0.6t2.9 1.3 1.8 1.9 1 1.9 0.2 1.6v13.2z m-15 0.5q0 1.9 1.6 2.9 1.5 1 3.1 0.5 1.9-0.5 2.6-2.7 0.3-1 0.3-2.3v-3.6q-1.3 0-2.5 0.3t-2.4 0.7-1.9 1.6-0.8 2.6z' })
        )
    );
};

exports.default = FaAmazon;
module.exports = exports['default'];