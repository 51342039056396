'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaTrophy = function FaTrophy(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm11.7 19.7q-1.6-3.6-1.6-8.3h-5.7v2.2q0 1.7 2.1 3.6t5.2 2.5z m24.1-6.1v-2.2h-5.7q0 4.7-1.7 8.3 3.2-0.6 5.3-2.5t2.1-3.6z m2.8-2.9v2.9q0 1.6-0.9 3.2t-2.5 2.9-3.8 2.1-4.9 1q-0.9 1.2-2.1 2.2-0.8 0.7-1.1 1.6t-0.4 2q0 1.2 0.7 2t2.2 0.8q1.7 0 3 1t1.3 2.6v1.4q0 0.3-0.2 0.5t-0.5 0.2h-18.6q-0.3 0-0.5-0.2t-0.2-0.5v-1.4q0-1.5 1.3-2.6t3-1q1.5 0 2.1-0.8t0.7-2q0-1.2-0.3-2t-1.2-1.6q-1.2-1-2.1-2.2-2.5-0.1-4.8-1t-3.9-2.1-2.5-2.9-0.9-3.2v-2.9q0-0.9 0.6-1.5t1.5-0.6h6.5v-2.2q0-1.4 1-2.5t2.5-1h12.9q1.5 0 2.5 1t1.1 2.5v2.2h6.4q0.9 0 1.5 0.6t0.6 1.5z' })
        )
    );
};

exports.default = FaTrophy;
module.exports = exports['default'];