'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaFlag = function FaFlag(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm7 6.1q0 1.5-1.4 2.4v27.5q0 0.3-0.2 0.5t-0.5 0.2h-1.4q-0.3 0-0.5-0.2t-0.2-0.5v-27.5q-1.4-0.9-1.4-2.4 0-1.2 0.8-2t2-0.8 2 0.8 0.8 2z m32 1.4v16.6q0 0.5-0.2 0.8t-0.9 0.6q-4.7 2.5-8 2.5-1.4 0-2.7-0.4t-2.4-1.1-2.5-1-3.1-0.5q-4.2 0-10.1 3.2-0.4 0.2-0.7 0.2-0.6 0-1-0.4t-0.4-1v-16.2q0-0.7 0.6-1.2 0.5-0.3 1.8-0.9 5.1-2.6 9.1-2.6 2.4 0 4.4 0.6t4.8 1.9q0.8 0.4 1.9 0.4 1.1 0 2.5-0.4t2.4-1.1 1.9-1 1.2-0.4q0.6 0 1 0.4t0.4 1z' })
        )
    );
};

exports.default = FaFlag;
module.exports = exports['default'];