'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaSafari = function FaSafari(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm21.2 19.9q0 0.6-0.4 1t-0.9 0.5q-0.6 0-1-0.4t-0.4-0.9q0-0.6 0.3-1t1-0.5 1 0.4 0.4 0.9z m0.3 1.3l7.8-12.9q-0.2 0.1-1.5 1.4t-2.8 2.6-3 2.8-2.6 2.5-1.2 1.1l-7.8 13q0.2-0.2 1.5-1.4t2.9-2.6 3-2.9 2.6-2.4 1.1-1.2z m14.5-1.2q0 4.5-2.4 8.3 0-0.1-0.3-0.3t-0.6-0.3-0.4-0.2q-0.3 0-0.3 0.3 0 0.2 1.3 1-1.6 2.5-4.1 4.2t-5.4 2.5l-0.3-1.5q-0.1-0.2-0.4-0.2-0.1 0-0.2 0.1t0 0.2l0.4 1.5q-1.6 0.4-3.3 0.4-4.4 0-8.3-2.4 0 0 0.3-0.4t0.5-0.8 0.2-0.4q0-0.3-0.3-0.3-0.1 0-0.4 0.3t-0.5 0.8-0.3 0.5q-2.5-1.7-4.3-4.2t-2.4-5.4l1.5-0.4q0.2 0 0.2-0.3 0-0.1-0.1-0.2t-0.2 0l-1.5 0.3q-0.4-1.6-0.4-3.1 0-4.6 2.5-8.5 0 0.1 0.4 0.3t0.7 0.4 0.3 0.2q0.3 0 0.3-0.3 0-0.1-0.2-0.3t-0.8-0.5l-0.4-0.3q1.7-2.5 4.2-4.2t5.4-2.4l0.4 1.5q0 0.3 0.3 0.3 0.1 0 0.2-0.2t0-0.2l-0.3-1.5q1.6-0.3 3-0.3 4.6 0 8.5 2.5-0.9 1.2-0.9 1.4 0 0.3 0.3 0.3 0.2 0 1-1.4 2.5 1.7 4.2 4.1t2.4 5.4l-1.2 0.3q-0.3 0-0.3 0.3 0 0.2 0.2 0.2t0.2 0.1l1.2-0.3q0.4 1.6 0.4 3.1z m1.9 0q0-3.6-1.5-6.9t-3.8-5.7-5.7-3.8-6.9-1.5-6.9 1.5-5.7 3.8-3.8 5.7-1.5 6.9 1.5 6.9 3.8 5.7 5.7 3.8 6.9 1.5 6.9-1.5 5.7-3.8 3.8-5.7 1.5-6.9z m2.1 0q0 4.1-1.6 7.8t-4.2 6.4-6.4 4.2-7.8 1.6-7.8-1.6-6.4-4.2-4.2-6.4-1.6-7.8 1.6-7.8 4.2-6.4 6.4-4.2 7.8-1.6 7.8 1.6 6.4 4.2 4.2 6.4 1.6 7.8z' })
        )
    );
};

exports.default = FaSafari;
module.exports = exports['default'];