'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaLanguage = function FaLanguage(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm17.6 24.1q0 0-0.3 0t-0.7-0.3l-0.4-0.2q-1-0.5-2-1.1-0.1-0.1-0.9-0.7t-0.8-0.6q-1.5 2.3-3 4-1.8 2.1-2.4 2.5-0.1 0-0.4 0t-0.4 0q0.1 0 1.8-2 0.5-0.5 1.9-2.6t1.8-2.6q0.4-0.7 1.1-2.2t0.8-1.7q-0.2-0.1-2.4 0.7-0.2 0-0.7 0.2t-0.7 0.2-0.4 0.1q-0.1 0-0.1 0.2t0 0.2q-0.1 0.3-0.7 0.4-0.5 0.1-1 0-0.4-0.1-0.6-0.5-0.1-0.1-0.1-0.5 0.1-0.1 0.5-0.1t0.7-0.2q1.3-0.3 2.3-0.7 2.2-0.8 2.3-0.8 0.2 0 0.9-0.4t1-0.5q0.2 0 0.5-0.1t0.3-0.2 0.2 0q0 0.3-0.1 0.8 0 0-0.2 0.6t-0.6 1.2-0.4 0.7q-0.6 1.1-1.7 2.9l1.4 0.7q0.3 0.1 1.7 0.7t1.5 0.6q0.1 0 0.2 0.6t0.1 0.7z m-4.6-10.9q0.1 0.3-0.1 0.6-0.2 0.6-1.1 0.9-0.7 0.3-1.3 0.3-0.6-0.1-1.1-0.6-0.3-0.4-0.4-0.9l0-0.1q0.1 0.1 0.4 0.1t0.6 0 1.3-0.4q0.8-0.2 1.3-0.3 0.3 0 0.4 0.4z m15.6 2.9l1.4 5.1-3.1-1z m-24.7 17.9l15.5-5.2v-23.1l-15.5 5.2v23.1z m27.7-7.1l2.2 0.7-4-14.7-2.2-0.7-4.8 12 2.2 0.7 1-2.5 4.7 1.5z m-11.3-21.5l12.8 4.1v-8.5z m7 29.5l3.5 0.3-1.2 3.6-0.9-1.5q-2.9 1.9-6.1 2.4-1.3 0.3-2.1 0.3h-1.9q-1.7 0-4.4-0.9t-4.1-1.9q-0.2-0.1-0.2-0.3 0-0.2 0.1-0.3t0.3-0.2q0.1 0 0.4 0.2t0.7 0.4 0.5 0.2q1.6 0.9 3.5 1.4t3.5 0.6q2.2 0 3.8-0.4t3.5-1.1q0.3-0.2 0.7-0.3t0.7-0.5 0.6-0.3z m10-24.1v24.1l-17.3-5.5q-0.3 0.2-8.4 2.9t-8.2 2.7q-0.3 0-0.4-0.3 0 0 0-0.1v-24q0.1-0.2 0.1-0.2 0.1-0.2 0.4-0.3 2.4-0.8 3.4-1.1v-8.6l12.4 4.4q0.1 0 3.6-1.2t7.1-2.4 3.6-1.2q0.4 0 0.4 0.5v9.3z' })
        )
    );
};

exports.default = FaLanguage;
module.exports = exports['default'];