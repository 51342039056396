'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaSubway = function FaSubway(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm27.3 0q4.1 0 7.1 2.1t2.9 5v20q0 2.9-2.8 5t-6.8 2.2l4.7 4.5q0.4 0.3 0.2 0.8t-0.7 0.4h-23.5q-0.5 0-0.7-0.4t0.2-0.8l4.7-4.5q-4-0.2-6.8-2.2t-2.8-5v-20q0-2.9 2.9-5t7.1-2.1h14.3z m-17.9 29.3q1.5 0 2.5-1.1t1.1-2.5-1.1-2.5-2.5-1.1-2.5 1.1-1 2.5 1 2.5 2.5 1.1z m9.3-12.2v-11.4h-12.1v11.4h12.1z m12.2 12.2q1.4 0 2.5-1.1t1-2.5-1-2.5-2.5-1.1-2.6 1.1-1 2.5 1 2.5 2.6 1.1z m3.5-12.2v-11.4h-12.8v11.4h12.8z' })
        )
    );
};

exports.default = FaSubway;
module.exports = exports['default'];