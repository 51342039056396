'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaHandGrabO = function FaHandGrabO(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm20.1 8.6q-1.1 0-2 0.8t-0.8 2v2.9h-0.7v-2.1q0-1.1-0.7-1.8t-1.8-0.8q-1.1 0-1.8 0.8t-0.7 1.7v9.6l-0.7-0.7v-3.8q0-1.1-0.8-1.8t-1.7-0.8q-1.1 0-1.8 0.8t-0.7 1.7v5q0 1.1 0.7 1.9l7 6.6q0.8 0.8 0.8 2.3 0 0.5 0.5 1t1 0.4h14.2q0.6 0 1-0.4t0.5-1v-0.6q0-0.9 0.2-1.7l2.4-9.8q0.2-0.8 0.2-1.7v-5.5q0-1-0.7-1.8t-1.8-0.7q-1 0-1.7 0.7t-0.8 1.8v0.7h-0.7v-2.8q0-0.9-0.5-1.6t-1.5-0.9q-0.3-0.1-0.5-0.1-1 0-1.8 0.8t-0.7 1.7v2.9h-0.7v-2.7q0-1.2-0.7-2t-1.9-1q-0.1 0-0.3 0z m0-2.9q1.9 0 3.4 1.1 1.2-0.7 2.7-0.7 1.3 0 2.5 0.6t1.9 1.7q0.6-0.2 1.3-0.2 2.3 0 3.8 1.6t1.6 3.8v5.5q0 1.2-0.3 2.4l-2.4 9.8q-0.2 0.5-0.2 1.6 0 1.7-1.2 3t-3.1 1.2h-14.2q-1.9 0-3.1-1.3t-1.2-3.1l-6.9-6.7q-1.7-1.6-1.7-3.9v-5q0-2.2 1.6-3.7t3.8-1.6q0.2 0 0.3 0 0.1-2.1 1.7-3.6t3.7-1.4q1.1 0 2.2 0.5 1.6-1.6 3.8-1.6z' })
        )
    );
};

exports.default = FaHandGrabO;
module.exports = exports['default'];