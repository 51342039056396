'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaVimeo = function FaVimeo(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm38.1 11.6q-0.2 5.2-7.4 14.5-7.4 9.6-12.5 9.6-3.2 0-5.4-5.9-0.9-3.5-2.9-10.7-1.6-5.9-3.5-5.9-0.4 0-2.9 1.7l-1.7-2.2q0.6-0.4 2.4-2.1t2.9-2.6q3.5-3.1 5.4-3.2 2.1-0.2 3.4 1.2t1.8 4.5q1 6.4 1.5 8.4 1.2 5.5 2.7 5.5 1.1 0 3.4-3.6 2.3-3.6 2.5-5.5 0.3-3.1-2.5-3.1-1.2 0-2.7 0.6 2.7-8.8 10.3-8.5 5.6 0.2 5.2 7.3z' })
        )
    );
};

exports.default = FaVimeo;
module.exports = exports['default'];