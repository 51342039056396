'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaTree = function FaTree(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm36.6 32.9q0 0.5-0.5 1t-1 0.4h-10.3q0.1 0.4 0.2 1.9t0.1 2.5q0 0.5-0.4 0.9t-1 0.4h-7.1q-0.6 0-1-0.4t-0.4-0.9q0-0.9 0.1-2.5t0.2-1.9h-10.4q-0.5 0-1-0.4t-0.4-1 0.4-1l9-9h-5.1q-0.6 0-1-0.5t-0.4-1 0.4-1l9-9h-4.4q-0.6 0-1-0.4t-0.5-1 0.5-1l8.5-8.6q0.5-0.4 1-0.4t1 0.4l8.6 8.6q0.4 0.4 0.4 1t-0.4 1-1 0.4h-4.4l9 9q0.4 0.4 0.4 1t-0.4 1-1 0.5h-5.1l8.9 8.9q0.5 0.5 0.5 1.1z' })
        )
    );
};

exports.default = FaTree;
module.exports = exports['default'];