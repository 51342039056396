'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaPercent = function FaPercent(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm31.6 28.6q0-1.2-0.9-2t-2-0.9-2 0.9-0.8 2 0.8 2 2 0.8 2-0.8 0.9-2z m-17.2-17.2q0-1.1-0.8-2t-2-0.8-2 0.8-0.9 2 0.9 2 2 0.9 2-0.9 0.8-2z m22.9 17.2q0 3.5-2.5 6t-6.1 2.5-6-2.5-2.6-6 2.6-6.1 6-2.5 6.1 2.5 2.5 6.1z m-2.2-24.3q0 0.4-0.2 0.8l-23.6 31.5q-0.4 0.5-1.2 0.5h-3.5q-0.6 0-1-0.4t-0.5-1q0-0.4 0.3-0.8l23.6-31.5q0.4-0.5 1.1-0.5h3.6q0.6 0 1 0.4t0.4 1z m-15 7.1q0 3.6-2.5 6.1t-6 2.5-6.1-2.5-2.5-6.1 2.5-6 6.1-2.5 6 2.5 2.5 6z' })
        )
    );
};

exports.default = FaPercent;
module.exports = exports['default'];