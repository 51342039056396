'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaAmericanSignLanguageInterpreting = function FaAmericanSignLanguageInterpreting(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm18.1 21.1q-1.1 0-1.5-0.9-0.3-0.6-0.8-1t-1.2-0.3q-1 0-1.6 0.6t-0.7 1.6q0 1 0.7 1.6l0.1 0.1q0.6 0.6 1.5 0.6 0.6 0 1.2-0.4t0.8-0.9q0.4-0.9 1.5-1z m9.9-2.2q0-1-0.6-1.6l-0.2-0.1q-0.6-0.6-1.4-0.6-0.7 0-1.2 0.4t-0.9 0.9q-0.4 0.9-1.4 1 1 0 1.4 0.9 0.3 0.6 0.9 1t1.2 0.3q0.9 0 1.6-0.6t0.6-1.6z m-7.4-3.9q-0.3 0.6-1 0.8t-1.3 0q-1.1-0.6-2.3-0.6-0.9 0-1.8 0.3 0.1 0 0.2 0t0.2 0q1.6 0 2.9 0.9t2.1 2.3q0.3 0.7 0.1 1.3t-0.9 1q-0.3 0.1-0.7 0.1 0.4 0 0.7 0.2 0.6 0.3 0.9 0.9t-0.1 1.3q-0.7 1.5-2.1 2.3t-2.9 0.9h-0.1q-0.3 0-0.8 0l-5.1-0.5-4.2 2.1q-0.2 0.1-0.5 0.1-0.7 0-1-0.6l-2.8-5.6q-0.2-0.4 0-0.8t0.5-0.7l3.6-2.1 2.6-4.7q0.3-2.7 1.6-5.1t3.4-4.1q0.6-0.4 1.3-0.4t1.1 0.6 0.4 1.3-0.6 1.1q-1.3 1-2.1 2.2 2.2-1.4 4.7-1.7 0.7-0.1 1.2 0.3t0.7 1.1q0.1 0.7-0.4 1.3t-1.1 0.6q-1.5 0.2-2.8 1 0.8-0.2 1.8-0.2 2 0 3.8 0.9 0.6 0.3 0.8 0.9t0 1.3z m16.8-2.8l2.8 5.6q0.2 0.4 0.1 0.8t-0.5 0.7l-3.7 2.1-2.6 4.7q-0.3 2.7-1.6 5.1t-3.4 4.1q-0.5 0.4-1.1 0.4-0.8 0-1.3-0.6-0.4-0.6-0.3-1.3t0.6-1.1q1.2-1 2-2.2-2.1 1.4-4.7 1.7 0 0.1-0.2 0.1-0.6 0-1.1-0.5t-0.5-1q-0.1-0.7 0.3-1.3t1.1-0.6q1.5-0.2 2.8-1-0.8 0.2-1.7 0.2-2 0-3.8-0.9-0.7-0.3-0.9-0.9t0.1-1.3q0.3-0.6 0.9-0.9t1.3 0.1q1.1 0.6 2.4 0.6 0.9 0 1.7-0.3 0 0-0.2 0t-0.1 0q-1.6 0-3-0.9t-2.1-2.3q-0.3-0.7 0-1.3t0.8-1q0.3-0.1 0.7-0.1-0.4 0-0.7-0.2-0.6-0.3-0.8-0.9t0-1.3q0.7-1.5 2.1-2.3t3-0.9h0.1q0.2 0 0.7 0l5.1 0.5 4.2-2.1q0.3-0.1 0.5-0.1 0.7 0 1 0.6z' })
        )
    );
};

exports.default = FaAmericanSignLanguageInterpreting;
module.exports = exports['default'];