'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaRocket = function FaRocket(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm33.1 10q0-0.9-0.6-1.5t-1.5-0.6-1.5 0.6-0.6 1.5 0.6 1.5 1.5 0.6 1.5-0.6 0.6-1.5z m5-6.4q0 5.5-1.6 9.6t-5.7 8q-1.8 1.8-4.4 4l-0.4 8.4q0 0.4-0.4 0.6l-8.5 5q-0.2 0.1-0.4 0.1-0.3 0-0.5-0.2l-1.4-1.4q-0.3-0.4-0.2-0.8l1.9-6.1-6.3-6.3-6.1 1.9q-0.1 0-0.2 0-0.4 0-0.6-0.2l-1.4-1.4q-0.4-0.4-0.1-0.9l5-8.5q0.2-0.4 0.6-0.4l8.4-0.4q2.2-2.6 4-4.4 4.2-4.2 8-5.8t9.6-1.5q0.3 0 0.5 0.2t0.2 0.5z' })
        )
    );
};

exports.default = FaRocket;
module.exports = exports['default'];