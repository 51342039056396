'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaHandORight = function FaHandORight(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm5.7 30q0-0.6-0.4-1t-1-0.4-1 0.4-0.4 1 0.4 1 1 0.4 1-0.4 0.4-1z m31.4-12.9q0-1.1-0.8-2t-2-0.8h-12.9q0-0.5 0.4-1.1t0.7-1.2 0.7-1.5 0.4-1.9q0-1.5-1-2.2t-2.6-0.7q-0.5 0-2 3.1-0.5 1-0.8 1.5-0.9 1.4-2.5 3.2-1.6 1.8-2.3 2.4-1.5 1.2-3.1 1.2h-0.7v14.3h0.7q1.6 0 3.7 0.7t4.3 1.5 4 0.7q4.3 0 4.3-3.7 0-0.6-0.2-1.3 0.7-0.3 1.1-1.2t0.4-1.6-0.4-1.5q1.2-1.2 1.2-2.7 0-0.6-0.2-1.2t-0.6-1.1h7.4q1.1 0 2-0.8t0.8-2.1z m2.9 0q0 2.4-1.7 4.1t-4 1.7h-3.8q-0.1 1.3-0.8 2.6 0.1 0.5 0.1 1 0 2.2-1.4 3.9 0 3.1-1.9 4.9t-5 1.8q-3 0-7.2-1.5-3.7-1.3-5-1.3h-6.4q-1.2 0-2.1-0.9t-0.8-2v-14.3q0-1.1 0.8-2t2.1-0.8h6.4q0.2 0 0.5-0.1t0.5-0.3 0.5-0.4 0.5-0.5 0.5-0.5 0.4-0.5 0.3-0.4q1.5-1.6 2.3-2.8 0.2-0.5 0.7-1.4t0.8-1.6 0.9-1.4 1.2-1.1 1.6-0.4q2.8 0 4.6 1.5t1.8 4.2q0 1.5-0.5 2.8h8.4q2.3 0 4 1.7t1.7 4z' })
        )
    );
};

exports.default = FaHandORight;
module.exports = exports['default'];