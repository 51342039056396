'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaMercury = function FaMercury(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm25.5 7.1q3.3 1.6 5.2 4.7t2 6.8q0 4.9-3.3 8.6t-8.1 4.1v3h2.1q0.3 0 0.5 0.2t0.2 0.5v1.4q0 0.3-0.2 0.5t-0.5 0.2h-2.1v2.2q0 0.3-0.2 0.5t-0.5 0.2h-1.5q-0.3 0-0.5-0.2t-0.2-0.5v-2.2h-2.1q-0.3 0-0.5-0.2t-0.2-0.5v-1.4q0-0.3 0.2-0.5t0.5-0.2h2.1v-3q-4.8-0.5-8.1-4.1t-3.3-8.6q0-3.8 2-6.8t5.2-4.7q-3.7-2.2-5.1-6.1-0.1-0.4 0.1-0.7t0.6-0.3h1.5q0.5 0 0.7 0.4 0.9 2.4 3.1 3.9t4.8 1.4 4.7-1.4 3.2-3.9q0.1-0.4 0.8-0.4h1.3q0.4 0 0.6 0.3t0.1 0.7q-1.4 3.9-5.1 6.1z m-5.6 21.5q4.1 0 7-3t3-7-3-7.1-7-2.9-7.1 2.9-2.9 7.1 2.9 7 7.1 3z' })
        )
    );
};

exports.default = FaMercury;
module.exports = exports['default'];