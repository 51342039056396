'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaSnapchatGhost = function FaSnapchatGhost(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm20.4 2.9q3-0.1 5.4 1.5t3.6 4.3q0.7 1.3 0.7 4 0 1-0.2 4.2 0.3 0.2 0.6 0.2 0.4 0 1.1-0.3t1.2-0.3q0.6 0 1.2 0.4t0.6 1q0 0.7-0.7 1.2t-1.5 0.7-1.6 0.7-0.7 1q0 0.4 0.3 1 0.8 1.8 2.3 3.3t3.2 2.3q0.6 0.3 1.8 0.5 0.6 0.1 0.6 0.8 0 1.6-4.9 2.3-0.1 0.2-0.2 0.9t-0.3 1-0.8 0.4q-0.4 0-1.4-0.1t-1.4-0.2q-0.8 0-1.4 0.1-0.7 0.1-1.4 0.5t-1.3 0.9-1.3 0.9-1.7 0.7-2.2 0.3q-1.1 0-2.1-0.3t-1.7-0.7-1.3-0.9-1.3-0.9-1.4-0.5q-0.5-0.1-1.4-0.1-0.5 0-1.4 0.2t-1.3 0.2q-0.6 0-0.8-0.5t-0.3-1-0.3-0.9q-4.9-0.7-4.9-2.3 0-0.7 0.7-0.8 1.1-0.2 1.7-0.5 1.8-0.7 3.3-2.3t2.3-3.3q0.2-0.6 0.2-1 0-0.6-0.7-1t-1.5-0.7-1.6-0.7-0.7-1.2q0-0.6 0.6-1t1.2-0.4q0.4 0 1.1 0.3t1.2 0.3q0.4 0 0.7-0.2-0.2-3.1-0.2-4.2 0-2.7 0.6-4 1.4-3.1 3.8-4.4t5.9-1.4z' })
        )
    );
};

exports.default = FaSnapchatGhost;
module.exports = exports['default'];