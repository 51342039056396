'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaEyedropper = function FaEyedropper(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm37.9 2.1q2.1 2.1 2.1 5.1t-2.1 5l-5 5 2.3 2.3q0.2 0.2 0.2 0.5t-0.2 0.5l-4.7 4.7q-0.2 0.2-0.5 0.2t-0.5-0.2l-2.4-2.3-13.4 13.4q-0.8 0.8-2 0.8h-4.6l-5.7 2.9-1.4-1.4 2.9-5.7v-4.6q0-1.2 0.8-2l13.4-13.4-2.3-2.4q-0.2-0.2-0.2-0.5t0.2-0.5l4.7-4.7q0.2-0.2 0.5-0.2t0.5 0.2l2.3 2.3 5-5q2.1-2.1 5-2.1t5.1 2.1z m-26.5 30.8l12.9-12.9-4.3-4.3-12.9 12.9v4.3h4.3z' })
        )
    );
};

exports.default = FaEyedropper;
module.exports = exports['default'];