"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "split", {
  enumerable: true,
  get: function get() {
    return _split["default"];
  }
});
Object.defineProperty(exports, "diff", {
  enumerable: true,
  get: function get() {
    return _diff["default"];
  }
});
exports["default"] = void 0;

var _ace = _interopRequireDefault(require("./ace.js"));

var _split = _interopRequireDefault(require("./split.js"));

var _diff = _interopRequireDefault(require("./diff.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _ace["default"];
exports["default"] = _default;