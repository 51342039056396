'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaSunO = function FaSunO(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm32.9 20q0-2.6-1.1-5t-2.7-4.1-4.1-2.7-5-1.1-5 1.1-4.1 2.7-2.7 4.1-1.1 5 1.1 5 2.7 4.1 4.1 2.7 5 1.1 5-1.1 4.1-2.7 2.7-4.1 1.1-5z m6.1 6.2q-0.1 0.3-0.4 0.4l-6.5 2.2v6.8q0 0.4-0.3 0.6-0.4 0.2-0.7 0.1l-6.5-2.1-4 5.5q-0.2 0.3-0.6 0.3t-0.6-0.3l-4-5.5-6.5 2.1q-0.3 0.1-0.7-0.1-0.3-0.2-0.3-0.6v-6.8l-6.5-2.2q-0.3-0.1-0.4-0.4-0.1-0.4 0.1-0.7l4-5.5-4-5.5q-0.2-0.3-0.1-0.7 0.1-0.3 0.4-0.4l6.5-2.2v-6.8q0-0.4 0.3-0.6 0.4-0.2 0.7-0.1l6.5 2.1 4-5.5q0.2-0.3 0.6-0.3t0.6 0.3l4 5.5 6.5-2.1q0.3-0.1 0.7 0.1 0.3 0.2 0.3 0.6v6.8l6.5 2.2q0.3 0.1 0.4 0.4 0.1 0.4-0.1 0.7l-4 5.5 4 5.5q0.2 0.3 0.1 0.7z' })
        )
    );
};

exports.default = FaSunO;
module.exports = exports['default'];