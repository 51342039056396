'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaBullhorn = function FaBullhorn(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm37.1 14.3q1.2 0 2.1 0.8t0.8 2-0.8 2.1-2.1 0.8v8.6q0 1.1-0.8 2t-2 0.8q-9.3-7.7-18.1-8.5-1.3 0.5-2.1 1.5t-0.7 2.3 0.9 2q-0.4 0.8-0.5 1.5t0.2 1.3 0.7 1.2 1.1 1.1 1.3 1.2q-0.6 1.2-2.4 1.8t-3.8 0.3-2.9-1.3q-0.2-0.5-0.7-1.9t-0.7-2.1-0.5-2-0.4-2.3 0.1-2.2 0.5-2.4h-2.7q-1.5 0-2.5-1.1t-1.1-2.5v-4.3q0-1.5 1.1-2.5t2.5-1.1h10.7q9.7 0 20-8.5 1.1 0 2 0.8t0.8 2v8.6z m-2.8 13.5v-21.3q-8.8 6.7-17.2 7.6v6.1q8.5 0.9 17.2 7.6z' })
        )
    );
};

exports.default = FaBullhorn;
module.exports = exports['default'];