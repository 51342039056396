'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaItalic = function FaItalic(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm8.5 37.1l0.4-1.9q0.1 0 1.8-0.5t2.5-0.8q0.6-0.8 0.9-2.3 0-0.1 1.4-6.4t2.5-12.2 1.2-6.6v-0.5q-0.5-0.3-1.2-0.4t-1.6-0.2-1.3-0.1l0.5-2.3q0.7 0 2.6 0.1t3.4 0.2 2.7 0q1 0 2.2 0t2.7-0.2 2.2-0.1q-0.2 0.8-0.5 1.9-0.6 0.3-2.2 0.7t-2.5 0.7q-0.1 0.4-0.3 1t-0.2 0.9-0.1 1-0.2 0.9q-0.6 3.3-1.9 9.4t-1.8 7.9q0 0.2-0.3 1.3t-0.4 2-0.4 1.9-0.1 1.3l0 0.4q0.4 0.1 4.2 0.7-0.1 0.9-0.4 2.2-0.2 0-0.7 0t-0.7 0q-0.7 0-2-0.2t-1.9-0.2q-3.1 0-4.6 0-1.1 0-3.2 0.2t-2.7 0.2z' })
        )
    );
};

exports.default = FaItalic;
module.exports = exports['default'];