'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaDesktop = function FaDesktop(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm37.2 22v-17.3q0-0.3-0.2-0.4t-0.5-0.2h-33.2q-0.2 0-0.4 0.2t-0.2 0.4v17.3q0 0.3 0.2 0.5t0.4 0.2h33.2q0.3 0 0.5-0.2t0.2-0.5z m2.7-17.3v22.6q0 1.4-1 2.4t-2.4 0.9h-11.3q0 0.8 0.4 1.6t0.6 1.5 0.4 0.9q0 0.6-0.4 1t-1 0.3h-10.6q-0.5 0-0.9-0.3t-0.4-1q0-0.3 0.3-0.9t0.7-1.5 0.3-1.6h-11.3q-1.3 0-2.3-0.9t-1-2.4v-22.6q0-1.4 1-2.3t2.3-1h33.2q1.4 0 2.4 1t1 2.3z' })
        )
    );
};

exports.default = FaDesktop;
module.exports = exports['default'];