'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaMixcloud = function FaMixcloud(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm28.8 23.5q0-1-0.6-1.8t-1.5-1.2q-0.1 0.8-0.4 1.6-0.1 0.4-0.5 0.6t-0.8 0.3q-0.2 0-0.4-0.1-0.5-0.1-0.8-0.6t-0.1-1.1q0.4-1.2 0.4-2.5 0-2.1-1-3.9t-2.9-2.9-4-1.1q-2.4 0-4.3 1.3t-3 3.4q1.9 0.5 3.3 1.8 0.4 0.4 0.4 1t-0.4 0.9-0.9 0.4-1-0.4q-1.3-1.3-3.1-1.3-1.9 0-3.2 1.3t-1.3 3.2 1.3 3.1 3.2 1.3h18.3q1.4 0 2.3-0.9t1-2.4z m2.7 0q0 2.5-1.8 4.3t-4.2 1.7h-18.3q-3 0-5.1-2.1t-2.1-5q0-2.7 1.8-4.7t4.3-2.4q1.1-3.2 3.9-5.2t6.2-2q4.1 0 7.1 2.8t3.5 6.8q2 0.4 3.3 2.1t1.4 3.7z m4.4 0q0 3.1-1.7 5.6-0.4 0.6-1.2 0.6-0.4 0-0.7-0.2-0.5-0.3-0.6-0.9t0.2-1q1.3-1.8 1.3-4.1t-1.3-4q-0.3-0.5-0.2-1t0.6-0.9 1-0.2 0.9 0.6q1.7 2.5 1.7 5.5z m4.4 0q0 4.3-2.3 7.8-0.4 0.6-1.1 0.6-0.4 0-0.8-0.2-0.4-0.4-0.5-0.9t0.2-1q1.9-2.9 1.9-6.3 0-3.4-1.9-6.2-0.3-0.5-0.2-1t0.5-0.9q0.5-0.3 1-0.2t0.9 0.6q2.3 3.5 2.3 7.7z' })
        )
    );
};

exports.default = FaMixcloud;
module.exports = exports['default'];