'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaChainBroken = function FaChainBroken(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm11.3 28.4l-5.7 5.7q-0.2 0.2-0.5 0.2-0.3 0-0.5-0.2-0.2-0.2-0.2-0.5t0.2-0.5l5.7-5.8q0.2-0.2 0.5-0.2t0.5 0.2q0.2 0.3 0.2 0.6t-0.2 0.5z m3.8 0.9v7.1q0 0.3-0.2 0.5t-0.5 0.2-0.6-0.2-0.2-0.5v-7.1q0-0.3 0.2-0.5t0.6-0.2 0.5 0.2 0.2 0.5z m-5-5q0 0.3-0.2 0.5t-0.5 0.2h-7.2q-0.3 0-0.5-0.2t-0.2-0.5 0.2-0.5 0.5-0.2h7.2q0.3 0 0.5 0.2t0.2 0.5z m28.2 2.8q0 2.7-1.9 4.6l-3.3 3.2q-1.8 1.9-4.5 1.9-2.7 0-4.6-1.9l-7.4-7.5q-0.5-0.5-1-1.2l5.4-0.4 6.1 6.1q0.6 0.6 1.5 0.6t1.5-0.6l3.3-3.3q0.6-0.6 0.6-1.5 0-0.9-0.6-1.5l-6.1-6.1 0.4-5.4q0.7 0.5 1.2 1l7.5 7.5q1.9 1.9 1.9 4.5z m-13.8-16.1l-5.3 0.4-6.1-6.1q-0.6-0.7-1.5-0.7-0.9 0-1.6 0.6l-3.2 3.3q-0.7 0.6-0.7 1.5 0 0.9 0.7 1.5l6.1 6.1-0.4 5.4q-0.8-0.5-1.3-0.9l-7.5-7.5q-1.8-2-1.8-4.6 0-2.7 1.9-4.5l3.2-3.3q1.9-1.8 4.6-1.8 2.7 0 4.5 1.9l7.5 7.4q0.4 0.5 0.9 1.3z m14.1 1.9q0 0.3-0.2 0.5t-0.5 0.2h-7.1q-0.3 0-0.5-0.2t-0.2-0.5 0.2-0.6 0.5-0.2h7.1q0.3 0 0.5 0.2t0.2 0.6z m-12.1-12.2v7.2q0 0.3-0.2 0.5t-0.5 0.2-0.5-0.2-0.2-0.5v-7.2q0-0.3 0.2-0.5t0.5-0.2 0.5 0.2 0.2 0.5z m9.1 3.4l-5.7 5.7q-0.3 0.2-0.5 0.2t-0.6-0.2q-0.2-0.2-0.2-0.5t0.2-0.5l5.8-5.7q0.2-0.2 0.5-0.2t0.5 0.2q0.2 0.2 0.2 0.5t-0.2 0.5z' })
        )
    );
};

exports.default = FaChainBroken;
module.exports = exports['default'];