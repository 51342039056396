'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaRetweet = function FaRetweet(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm26.6 32.6q0 0.3-0.2 0.5t-0.5 0.2h-19.9q-0.2 0-0.3-0.1t-0.2-0.1-0.1-0.2-0.1-0.2 0-0.2v-12.5h-4q-0.5 0-0.9-0.4t-0.4-0.9q0-0.5 0.3-0.9l6.7-8q0.4-0.4 1-0.4t1 0.4l6.6 8q0.3 0.4 0.3 0.9 0 0.5-0.3 0.9t-1 0.4h-4v8h12q0.3 0 0.5 0.2l3.3 4q0.2 0.2 0.2 0.4z m13.3-8.6q0 0.5-0.3 0.8l-6.7 8q-0.4 0.5-1 0.5t-1-0.5l-6.7-8q-0.3-0.3-0.3-0.8 0-0.6 0.4-0.9t0.9-0.4h4v-8h-11.9q-0.4 0-0.5-0.3l-3.4-3.9q-0.1-0.2-0.1-0.5 0-0.2 0.2-0.4t0.5-0.2h19.9q0.2 0 0.3 0t0.2 0.2 0.1 0.1 0 0.3 0.1 0.2v12.5h3.9q0.6 0 1 0.4t0.4 0.9z' })
        )
    );
};

exports.default = FaRetweet;
module.exports = exports['default'];