'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaTimesCircleO = function FaTimesCircleO(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm27.5 24.1l-3.3 3.2q-0.2 0.3-0.5 0.3t-0.5-0.3l-3.1-3-3 3q-0.2 0.3-0.5 0.3t-0.5-0.3l-3.3-3.2q-0.2-0.2-0.2-0.5t0.2-0.5l3.1-3.1-3.1-3.1q-0.2-0.2-0.2-0.5t0.2-0.5l3.3-3.2q0.2-0.3 0.5-0.3t0.5 0.3l3 3 3.1-3q0.2-0.3 0.5-0.3t0.5 0.3l3.3 3.2q0.2 0.2 0.2 0.5t-0.2 0.5l-3.1 3.1 3.1 3.1q0.2 0.2 0.2 0.5t-0.2 0.5z m4.8-4.1q0-3.3-1.6-6.1t-4.5-4.4-6.1-1.6-6.1 1.6-4.4 4.4-1.6 6.1 1.6 6.1 4.4 4.4 6.1 1.6 6.1-1.6 4.5-4.4 1.6-6.1z m5 0q0 4.7-2.3 8.6t-6.3 6.2-8.6 2.3-8.6-2.3-6.2-6.2-2.3-8.6 2.3-8.6 6.2-6.2 8.6-2.3 8.6 2.3 6.3 6.2 2.3 8.6z' })
        )
    );
};

exports.default = FaTimesCircleO;
module.exports = exports['default'];