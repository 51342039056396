'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaShip = function FaShip(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm35.2 32.8q0.3-0.4 0.8-0.4t0.9 0.4l2.5 2.5-1.8 1.7-1.6-1.6-1.6 1.6q-0.3 0.4-0.9 0.4t-0.8-0.4l-1.6-1.6-1.7 1.6q-0.3 0.4-0.8 0.4t-0.9-0.4l-1.6-1.6-1.6 1.6q-0.4 0.4-0.9 0.4t-0.9-0.4l-1.6-1.6-1.6 1.6q-0.4 0.4-0.9 0.4t-0.8-0.4l-1.7-1.6-1.6 1.6q-0.3 0.4-0.8 0.4t-0.9-0.4l-1.6-1.6-1.6 1.6q-0.4 0.4-0.9 0.4t-0.9-0.4l-1.6-1.6-1.6 1.6q-0.4 0.4-0.9 0.4t-0.8-0.4l-2.5-2.5 1.7-1.7 1.6 1.6 1.6-1.6q0.4-0.4 0.9-0.4t0.9 0.4l1.6 1.6 1.6-1.6q0.4-0.4 0.9-0.4t0.9 0.4l1.6 1.6 1.6-1.6q0.3-0.4 0.8-0.4t0.9 0.4l1.6 1.6 1.6-1.6q0.4-0.4 0.9-0.4t0.9 0.4l1.6 1.6 1.6-1.6q0.4-0.4 0.9-0.4t0.9 0.4l1.6 1.6 1.6-1.6q0.4-0.4 0.9-0.4t0.8 0.4l1.6 1.6z m-30.6-0.7q-0.4 0.3-0.9 0.3t-0.8-0.3l-2.5-2.5 1.7-1.8 1.6 1.6 1.6-1.6q0.4-0.3 0.9-0.3t0.9 0.3l1.6 1.6 1.2-1.2v-5.7l-4-6.1q-0.4-0.5-0.2-1.1t0.8-0.8l3.4-1.1v-5.8h2.5v-2.5h5v-2.5h5v2.5h4.9v2.5h2.5v5.8l3.4 1.1q0.6 0.2 0.8 0.8t-0.1 1.1l-4.1 6.1v5.7l0.4-0.4q0.4-0.3 0.9-0.3t0.8 0.3l1.6 1.6 1.7-1.6q0.3-0.3 0.8-0.3t0.9 0.3l2.5 2.5-1.8 1.8-1.6-1.7-1.6 1.7q-0.3 0.3-0.9 0.3t-0.8-0.3l-1.6-1.7-1.7 1.7q-0.3 0.3-0.8 0.3t-0.9-0.3l-1.6-1.7-1.6 1.7q-0.4 0.3-0.9 0.3t-0.9-0.3l-1.6-1.7-1.6 1.7q-0.4 0.3-0.9 0.3t-0.8-0.3l-1.7-1.7-1.6 1.7q-0.3 0.3-0.8 0.3t-0.9-0.3l-1.6-1.7-1.6 1.7q-0.4 0.3-0.9 0.3t-0.9-0.3l-1.6-1.7z m7.8-22v2.4l7.5-2.4 7.4 2.4v-2.4h-2.5v-2.5h-9.9v2.5h-2.5z' })
        )
    );
};

exports.default = FaShip;
module.exports = exports['default'];