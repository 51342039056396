'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaCcDinersClub = function FaCcDinersClub(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm15 26v-12.1q-1.8 0.7-3 2.4t-1.1 3.7 1.1 3.7 3 2.3z m8.8-6q0-2.1-1.1-3.7t-3-2.4v12.2q1.8-0.8 3-2.4t1.1-3.7z m3.8 0q0 2.8-1.4 5.1t-3.7 3.8-5.2 1.3q-2 0-3.9-0.8t-3.3-2.2-2.2-3.2-0.8-4q0-2.8 1.4-5.2t3.7-3.7 5.1-1.4 5.2 1.4 3.7 3.7 1.4 5.2z m6.7 0.1q0-2.4-1-4.6t-2.5-3.6-3.8-2.3-4.4-0.8h-5.3q-3 0-5.6 1.4t-4.1 4-1.6 5.9q0 3 1.5 5.6t4.2 4 5.6 1.5h5.3q2.3 0 4.4-0.9t3.8-2.3 2.5-3.6 1-4.3z m6-11.3v22.4q0 0.9-0.6 1.6t-1.6 0.6h-35.9q-0.9 0-1.5-0.6t-0.7-1.6v-22.4q0-0.9 0.7-1.6t1.5-0.6h35.9q0.9 0 1.6 0.6t0.6 1.6z' })
        )
    );
};

exports.default = FaCcDinersClub;
module.exports = exports['default'];