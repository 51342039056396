'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaFlagCheckered = function FaFlagCheckered(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm18.1 22.3v-4.2q-3.9 0.3-8.3 2.5v4.1q4.4-2.1 8.3-2.4z m0-9.1v-4.3q-3.7 0.1-8.3 2.7v4.1q4.6-2.4 8.3-2.5z m18.1 10v-4q-5.1 2.6-8.3 1.6v-4.9q-0.5-0.1-0.9-0.3-0.1-0.1-0.7-0.4t-0.7-0.4-0.7-0.3-0.8-0.3-0.7-0.3-0.8-0.3-0.7-0.2-0.9-0.1-0.9-0.1-0.9-0.1q-0.5 0-1.1 0.1v4.8h0.4q2.3 0 4.2 0.7t4.3 1.8q0.4 0.2 0.9 0.3v4.1q0.9 0.3 2 0.3 2.6 0 6.3-2z m0-9.3v-4.1q-3.6 2-6.6 2-1 0-1.7-0.2v4.3q3.2 0.9 8.3-2z m-29.2-7.8q0 0.7-0.4 1.4t-1 1v27.5q0 0.3-0.2 0.5t-0.5 0.2h-1.4q-0.3 0-0.5-0.2t-0.2-0.5v-27.5q-0.6-0.4-1-1t-0.4-1.4q0-1.2 0.8-2t2-0.8 2 0.8 0.8 2z m32 1.4v16.6q0 0.8-0.7 1.2-0.3 0.1-0.4 0.2-4.8 2.5-8 2.5-2 0-3.5-0.7l-0.6-0.3q-1.4-0.8-2.2-1.1t-1.9-0.6-2.5-0.3q-2.2 0-5.1 0.9t-5 2.3q-0.3 0.2-0.7 0.2-0.4 0-0.7-0.2-0.7-0.4-0.7-1.2v-16.2q0-0.8 0.6-1.2 0.8-0.4 1.8-0.9t2.4-1.1 3.4-1.1 3.3-0.4q2.5 0 4.6 0.6t4.5 1.9q0.9 0.4 2 0.4 2.6 0 6.7-2.4l0.7-0.4q0.7-0.3 1.4 0.1 0.6 0.4 0.6 1.2z' })
        )
    );
};

exports.default = FaFlagCheckered;
module.exports = exports['default'];