'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaUpload = function FaUpload(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm30.1 32.9q0-0.6-0.5-1t-1-0.5-1 0.5-0.4 1 0.4 1 1 0.4 1-0.4 0.5-1z m5.7 0q0-0.6-0.4-1t-1-0.5-1 0.5-0.5 1 0.5 1 1 0.4 1-0.4 0.4-1z m2.8-5v7.1q0 0.9-0.6 1.5t-1.5 0.6h-32.9q-0.8 0-1.5-0.6t-0.6-1.5v-7.1q0-0.9 0.6-1.6t1.5-0.6h9.6q0.4 1.3 1.5 2.1t2.5 0.8h5.7q1.4 0 2.5-0.8t1.6-2.1h9.5q0.9 0 1.5 0.6t0.6 1.6z m-7.2-14.5q-0.4 0.9-1.3 0.9h-5.7v10q0 0.6-0.5 1t-1 0.4h-5.7q-0.6 0-1-0.4t-0.4-1v-10h-5.7q-1 0-1.3-0.9-0.4-0.9 0.3-1.5l10-10q0.4-0.5 1-0.5t1 0.5l10 10q0.7 0.6 0.3 1.5z' })
        )
    );
};

exports.default = FaUpload;
module.exports = exports['default'];