'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaRenren = function FaRenren(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm28.3 35q-3.8 2.1-8.2 2.1-4.4 0-8.2-2.1 3.1-1.9 5.2-4.7t3-5.9q0.8 3.2 2.9 5.9t5.3 4.7z m-11.1-31.8v10.8q0 5.6-2.8 10.3t-7.4 6.8q-4-4.8-4-11.1 0-4.1 1.9-7.8t5.1-6 7.2-3z m20.1 16.8q0 6.3-4.1 11.1-4.5-2.2-7.3-6.8t-2.9-10.3v-10.8q4 0.6 7.3 3t5.1 6 1.9 7.8z' })
        )
    );
};

exports.default = FaRenren;
module.exports = exports['default'];