'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaCcPaypal = function FaCcPaypal(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm13 20.2q0 0.6-0.4 1.1t-1.1 0.4q-0.5 0-0.8-0.3t-0.3-0.8q0-0.6 0.4-1.1t1.1-0.4q0.5 0 0.8 0.3t0.3 0.8z m13.8-2.6q0 0.7-0.4 1t-1.2 0.2l-0.5 0 0.3-1.8q0-0.2 0.2-0.2h0.3q0.4 0 0.6 0t0.5 0.2 0.2 0.6z m6.1 2.6q0 0.6-0.4 1t-1.1 0.5q-0.5 0-0.8-0.3t-0.3-0.8q0-0.6 0.4-1.1t1.1-0.4q0.5 0 0.8 0.3t0.3 0.8z m-23.9-3q0-1.1-0.7-1.5t-1.8-0.5h-2.8q-0.3 0-0.3 0.4l-1.2 7.1q0 0.1 0.1 0.2t0.2 0.1h1.3q0.3 0 0.4-0.4l0.3-1.9q0-0.1 0.1-0.2t0.3-0.1 0.3 0 0.3 0 0.3 0q1.5 0 2.3-0.9t0.9-2.3z m5.4 5.4l0.7-4.5q0-0.1 0-0.2t-0.2-0.1h-1.3q-0.3 0-0.3 0.6-0.5-0.7-1.7-0.7-1.3 0-2.2 0.9t-0.8 2.2q0 1.1 0.6 1.7t1.6 0.6q0.5 0 1-0.2t0.8-0.6q0 0.2 0 0.4 0 0.3 0.2 0.3h1.2q0.3 0 0.4-0.4z m7.8-4.6q0 0-0.1-0.1t-0.1-0.1h-1.4q-0.2 0-0.3 0.2l-1.8 2.7-0.8-2.6q-0.1-0.3-0.4-0.3h-1.3q-0.1 0-0.2 0.1t0 0.1q0 0.1 0.3 1.1t0.7 2.1 0.5 1.3q-1.5 1.9-1.5 2.1 0 0.2 0.3 0.2h1.3q0.2 0 0.3-0.2l4.5-6.4q0-0.1 0-0.2z m6.7-0.8q0-1.1-0.7-1.5t-1.8-0.5h-2.7q-0.4 0-0.4 0.4l-1.2 7.1q0 0.1 0.1 0.2t0.2 0.1h1.4q0.2 0 0.3-0.3l0.3-2q0-0.1 0.1-0.2t0.3-0.1 0.3 0 0.3 0 0.2 0q1.6 0 2.4-0.9t0.9-2.3z m5.4 5.4l0.7-4.5q0-0.1-0.1-0.2t-0.1-0.1h-1.4q-0.2 0-0.3 0.6-0.4-0.7-1.6-0.7-1.3 0-2.2 0.9t-0.9 2.2q0 1.1 0.7 1.7t1.6 0.6q0.5 0 1-0.2t0.8-0.6q0 0 0 0.2t0 0.2q0 0.3 0.2 0.3h1.2q0.3 0 0.4-0.4z m3.8-7.1v0q0-0.3-0.2-0.3h-1.3q-0.2 0-0.3 0.2l-1.1 7.3 0 0q0 0.1 0.1 0.2t0.1 0.1h1.2q0.3 0 0.4-0.4z m-31.2 2.3q-0.1 0.6-0.5 0.8t-1 0.2l-0.6 0 0.3-1.8q0-0.2 0.2-0.2h0.3q0.7 0 1.1 0.2t0.2 0.8z m33.4-9v22.4q0 0.9-0.6 1.6t-1.6 0.6h-35.9q-0.9 0-1.5-0.6t-0.7-1.6v-22.4q0-0.9 0.7-1.6t1.5-0.6h35.9q0.9 0 1.6 0.6t0.6 1.6z' })
        )
    );
};

exports.default = FaCcPaypal;
module.exports = exports['default'];