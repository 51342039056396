'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIconBase = require('react-icon-base');

var _reactIconBase2 = _interopRequireDefault(_reactIconBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FaPaypal = function FaPaypal(props) {
    return _react2.default.createElement(
        _reactIconBase2.default,
        _extends({ viewBox: '0 0 40 40' }, props),
        _react2.default.createElement(
            'g',
            null,
            _react2.default.createElement('path', { d: 'm36.9 14.4q0.4 1.9-0.1 4.6-1.9 9.9-12.6 9.9h-1q-0.5 0-1 0.4t-0.5 0.9l-0.1 0.4-1.2 7.7-0.1 0.4q-0.1 0.6-0.5 0.9t-1 0.4h-5.6q-0.5 0-0.7-0.3t-0.2-0.8q0.2-1.3 0.6-3.8t0.5-3.7 0.6-3.8 0.7-3.7q0.1-0.8 0.9-0.8h2.9q3 0 5.3-0.5 3.9-0.9 6.4-3.2 2.3-2.1 3.5-5.5 0.5-1.6 0.7-3 0.1-0.1 0.1-0.2t0.1 0 0.1 0.1q1.8 1.3 2.2 3.6z m-3.8-6.3q0 2.4-1.1 5.3-1.7 5.2-6.7 7-2.5 0.9-5.6 1 0 0-2 0l-2 0q-2.3 0-2.7 2.1 0 0.2-1.9 11.8 0 0.3-0.2 0.3h-6.6q-0.5 0-0.8-0.4t-0.3-0.9l5.2-32.8q0.1-0.6 0.6-1.1t1.1-0.4h13.4q0.8 0 2.2 0.3t2.5 0.7q2.3 0.9 3.6 2.8t1.3 4.3z' })
        )
    );
};

exports.default = FaPaypal;
module.exports = exports['default'];